import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { IntroComponent } from './intro/intro.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'modal',
    loadChildren: () => import('./modal/modal.module').then(m => m.ModalPageModule)
  },
  {
    path: 'brief',
    loadChildren: () => import('./add/add.module').then(m => m.BriefPageModule)
  },
  {
    path: 'b-review',
    loadChildren: () => import('./b-review/b-review.module').then(m => m.BReviewPageModule)
  },
  {
    path: 's-review',
    loadChildren: () => import('./s-review/s-review.module').then(m => m.SReviewPageModule)
  },
  {
    path: 'bud',
    loadChildren: () => import('./bud/bud.module').then(m => m.BudPageModule)
  },
  {
    path: 'vid',
    loadChildren: () => import('./vid/vid.module').then(m => m.VidPageModule)
  },
  {
    path: 'mixtape',
    loadChildren: () => import('./mixtape/mixtape.module').then(m => m.MixtapePageModule)
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopPageModule)
  },
  {
    path: 'details',
    loadChildren: () => import('./pages/details/details.module').then(m => m.DetailsPageModule)
  },

  { path: 'intro', component: IntroComponent },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mixtapeApiKey: "c4fbed62654dba6e9d81210d3dc4a6af",
  MY_MAPS_API_KEY: "AIzaSyAlZCbHHrQZ1972pDlruu3cxJYePOnKuOo",
  firebase: {
    apiKey: "AIzaSyBqiD8yqdXqUfdln23xGnQeMm-65yhCzNI",
    authDomain: "burn-buddies.firebaseapp.com",
    projectId: "burn-buddies",
    storageBucket: "burn-buddies.appspot.com",
    messagingSenderId: "212774222619",
    appId: "1:212774222619:web:da65bf8371b5624b1c71be",
    measurementId: "G-691WR8J74R"
  },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

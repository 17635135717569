import { Directive } from '@angular/core';

@Directive({
  selector: '[appImageFade]'
})
export class ImageFadeDirective {

  constructor() { }

}

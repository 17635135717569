import { Injectable } from '@angular/core';


@Injectable()
export class GlobalVariable {


    shop: any[];

    constructor() {

    }

}

import { Component } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { Platform } from '@ionic/angular';
import { FcmService } from './services/fcm.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private statusBar: StatusBar, private platform: Platform,
    private fcmService: FcmService, private SplashScreen: SplashScreen ) {
    this.initializeApp();
  }


  initializeApp() {
    this.platform.ready().then(() => {
      this.SplashScreen.show();
      this.statusBar.styleDefault();
      setTimeout(() => {
       this.SplashScreen.hide();
        // this.loadData();
      }, 2000);
      this.fcmService.initPush();
      

    });
  }
}


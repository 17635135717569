import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController, isPlatform } from '@ionic/angular';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
})
export class IntroComponent implements OnInit {
  date: string;
  age: number;
  verified: boolean = false;
  ios: boolean = false;
  android: boolean = false;
  constructor(private toastController: ToastController, private router: Router) { }

  ngOnInit() { }


  showDate() {
    const userBirthdate = new Date(this.date)
    const ageDiffMilliseconds = Date.now() - userBirthdate.getTime();
    const ageDate = new Date(ageDiffMilliseconds);
    this.age = Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  async checkAge() {
    if (this.age >= 18) {
      this.verified = true;
      console.log(this.verified)
      localStorage.setItem('verified', "true");
      const adId = isPlatform("ios")
        ? this.ios = true
        : this.android = true;

    }
    else {
      // User is not over 18, display an error message
      const toast = await this.toastController.create({
        message: 'You must be over 18 years old to proceed.',
        duration: 2000,
        position: 'middle'
      });
      toast.present();
      setTimeout(() => {

        window.location.href = 'https://www.google.com';
      }, 1500)

    }
  }

  go() {
    this.router.navigate(['/tabs'])
  }

}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { GlobalVariable } from 'src/globals';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from '../environments/environment';
import { StarRatingModule } from 'angular-star-rating';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ImageFadeDirective } from './image-fade.directive';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { FCM } from '@ionic-native/fcm/ngx'
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { IntroComponent } from './intro/intro.component';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';

@NgModule({
  declarations: [AppComponent, ImageFadeDirective, IntroComponent],
  imports: [HttpClientModule, AngularFireAnalyticsModule, StarRatingModule.forRoot(), FormsModule, ReactiveFormsModule, AngularFirestoreModule, AngularFireStorageModule, BrowserModule, AngularFireModule.initializeApp(environment.firebase), IonicModule.forRoot(), AppRoutingModule],
  providers: [StatusBar, YoutubeVideoPlayer, SplashScreen, FCM, BackgroundMode, GlobalVariable, ScreenTrackingService,
    UserTrackingService, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }
